<template>
  <div class="manage-project-sales-vp">
    <div class="container fluid">
      <vacant-possession-table
        :getPendingVPListAPI="vpAPI.getPendingVPList"
        :completeVPAPI="vpAPI.completeVP"
        detailRouteName="ManageProjectVacantPossessionDetails"
      ></vacant-possession-table>
    </div>
  </div>
</template>

<script>
import VacantPossessionTable from "@/components/GlobalComponents/Shared/ProjectSales/VacantPossession/VacantPossessionTable";
import { projectVacantPossession as vpAPI } from "@/api";

export default {
  components: {
    VacantPossessionTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      vpAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>

